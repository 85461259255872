<template>
  <v-toolbar
    class="mobile_header_drawer d-print-none"
    flat
    height="56"
    width="768"
    v-if="!getIsMeasuring"
  >
    <menu-change-profile />
    <v-spacer />
    <span
      style="color: #f0f0f0; font-size: 0.85rem"
      class="mt-2 mr-2"
      v-if="unSyncedCounts"
    >
      Sync {{ syncedCounts }}/{{ unSyncedCounts }}...
    </span>
    <v-btn
      fab
      elevation="0"
      max-height="28px"
      max-width="28px"
      style="background-color: transparent !important"
      class="mt-2 mr-5"
    >
      <v-icon v-if="networkStatus" color="white" style="opacity: 0.8">
        mdi-wifi
      </v-icon>
    </v-btn>
    <subscribe-inviation />
  </v-toolbar>
</template>

<script>
import MenuChangeProfile from "./menuChangeProfile.vue";
import { mapGetters } from "vuex";
import SubscribeInviation from "@/components/SubscribeInviation.vue";

export default {
  components: {
    MenuChangeProfile,
    SubscribeInviation,
  },
  computed: {
    ...mapGetters("Authen", ["getUser"]),
    ...mapGetters("Scanner", ["getIsMeasuring"]),
    ...mapGetters("Offline", ["unSyncedCounts", "syncedCounts"]),
  },
  watch: {},
  data: function () {
    return {
      diaglog: false,
    };
  },
  mounted() {
    // console.log(this.$route);
  },
  methods: {
    startRequestBleDevice() {
      this.$store.commit("Scanner/OPEN_BLE_REQUEST");
    },
  },
};
</script>
