<template>
  <div>
    <v-btn
      fab
      elevation="0"
      max-height="28px"
      max-width="28px"
      style="background-color: transparent !important"
      @click="open"
      class="mt-2 mr-3"
    >
      <v-icon color="white" style="opacity: 0.8"> mdi-email-outline </v-icon>
      <v-badge
        color="pink"
        :content="notSeenCount > 5 ? '5+' : notSeenCount"
        v-if="notSeenCount"
        class="mb-4"
      >
      </v-badge>
    </v-btn>
    <v-dialog v-model="isShow" eager fullscreen>
      <v-card class="custom">
        <v-btn
          @click="isShow = false"
          class="btn_close mt-5"
          elevation="0"
          fixed
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="pt-10 ml-3">
          {{ $t("common.subscribeInvitation") }}
        </v-card-title>
        <v-card-text>
          <div class="content_card">
            <div style="text-align: center" v-if="loading" class="mt-2">
              <v-progress-circular
                indeterminate
                color="#fefefe"
              ></v-progress-circular>
            </div>
            <h5 v-if="!items.length" class="no_data" style="padding-left: 14px">
              {{ $t("common.nodata") }}
            </h5>
            <content-item
              v-for="(item, index) in items"
              :key="index"
              :item="item"
              @view="setIsSeen(item.id)"
            >
              <template v-slot:controller>
                <v-list-item-icon class="pt-1 pb-1">
                  <v-switch
                    v-model="item.isSubscribed"
                    flat
                    hide-details
                    light
                    inset
                    class="switch_subscription"
                    @change="toggleIsSubscribed(item.id, item.isSubscribed)"
                    color="#eeeeee"
                  ></v-switch>
                  <!-- <v-btn
                    small
                    fab
                    color="#eeeeee"
                    class="mt-3"
                    elevation="0"
                    style="
                      background-color: rgb(69, 78, 112);
                      width: 40px !important;
                      height: 40px !important;
                      border-radius: 40px;
                    "
                    :disabled="!item.phone || item.phone == $phone"
                    @click="
                      $event.stopPropagation();
                      $store.commit(
                        'Mobile/SET_MESSAGE_PHONE_STRING',
                        item.phone
                      );
                    "
                  >
                    <v-icon>mdi-message-text </v-icon>
                    <LottieNewMessage :phone="item.phone" />
                  </v-btn> -->
                  <v-btn
                    color="#eeeeee"
                    fab
                    elevation="0"
                    class="mt-3 ml-1"
                    style="
                      background-color: rgb(69, 78, 112);
                      width: 40px !important;
                      height: 40px !important;
                      border-radius: 40px;
                    "
                    @click="
                      $event.stopPropagation();
                      deleteInvite(item.id);
                    "
                    x-small
                  >
                    <v-icon>mdi-trash-can-outline </v-icon>
                  </v-btn>
                  <v-badge
                    color="pink"
                    class="mt-5"
                    v-if="!item.isSeen"
                    offset-x="-5"
                  >
                  </v-badge>
                </v-list-item-icon>
              </template>
            </content-item>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ContentItem from "@/components/cards/ContentItem";
import axios from "axios";
// import LottieNewMessage from "@/components/LottieNewMessage.vue";

export default {
  components: {
    ContentItem,
    // LottieNewMessage,
  },
  data() {
    return {
      isShow: false,
      loading: false,
      items: [],
      notSeenCount: 0,
    };
  },
  mounted() {
    this.listen();
    this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = true;
      var items = await this.$dbGet(`subscribe/${this.$uid}`);
      this.loading = false;
      this.render(items);
    },
    async render(items) {
      items = items
        ? Object.values(items).map((p) => ({
            ...p,
            image: false,
            title: `<span style="color:#e0e0e0;">${p.fullName || "NA"}</span>`,
            content: "",
            message: `<span style="color:#e0e0e0;">${p.phone || "NA"}</span>`,
          }))
        : [];
      this.items = items.sort((a, b) => a.fullName.localeCompare(b.fullName));
      this.notSeenCount = this.items.filter((i) => !i.isSeen).length;
      this.$store.commit(
        "Mobile/SET_SUBSCRIPTIONS",
        this.items.filter((i) => i.isSubscribed)
      );
    },
    async toggleIsSubscribed(id, changedState) {
      var item = this.items.find((i) => i.id == id);
      if (!item) return;
      if (changedState) {
        await this.$dbSet(`subscribe/${this.$uid}/${id}/isSubscribed`, true);
        this.notifyStatusSubscribe("Accept", id, this.$uid);
        await this.$dbSet(
          `users/${this.$uid}/patients/${item.phone}/fullName`,
          item.fullName
        );
      } else {
        await this.$dbSet(`subscribe/${this.$uid}/${id}/isSubscribed`, false);
        this.notifyStatusSubscribe("Cancel", id, this.$uid);
      }
    },
    async notifyStatusSubscribe(
      statusName = "Accept",
      inviterId,
      subscriberId
    ) {
      if (!inviterId || !subscriberId) return;
      if (!this.networkStatus) return;
      var payload = { inviterId, subscriberId };
      var resp = await axios.post(
        `https://us-central1-sandrasoft-8fe2b.cloudfunctions.net/notify${statusName}Subscribe`,
        payload
      );
      console.log(resp.data);
    },
    async deleteInvite(id) {
      if (!confirm(this.$t("common.deleteInvitation"))) return;
      var ind = this.items.findIndex((i) => i.id == id);
      if (ind == -1) return;
      this.items.splice(ind, 1);
      this.$dbRemove(`subscribe/${this.$uid}/${id}`);
      this.notifyStatusSubscribe("Cancel", id, this.$uid);
    },
    async setIsSeen(id) {
      await this.$dbSet(`subscribe/${this.$uid}/${id}/isSeen`, true);
    },
    async listen() {
      this.$dbWatcher(`subscribe/${this.$uid}`, async (invites) => {
        this.render(invites);
      });
    },
    async open() {
      this.isShow = true;
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  .v-card__text {
    font-size: 1rem;
  }
}
.no_data {
  color: #e0e0e0;
}
</style>
