<template>
  <v-avatar size="36px" style="border-radius: 0px">
    <img alt="Avatar" :src="user.avatar || '/icon.png'" />
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "Authen/getUser",
    }),
  },
};
</script>

<style></style>
